import { useState } from 'react'
import styled from 'styled-components'
import { Link } from '@reach/router'

import Burger from './Burger'
import Nav from './Nav'

import { ReactComponent as Logo } from '../svg/logo.svg'

const Header = () => {
  const [open, setOpen] = useState(false)

  return (
    <StyledHeader>
      <Link to='/'>
        <Logo />
      </Link>
      <Burger open={open} setOpen={setOpen} />
      <Nav open={open} setOpen={setOpen} />
    </StyledHeader>
  )
}

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  padding: 20px;
  svg {
    max-height: 50px;
    max-width: 150px;
  }
`

export default Header
