import styled from 'styled-components'
import { SRLWrapper } from 'simple-react-lightbox'

const Aluminijum = () => (
  <StyledPage>
    <div className='info'>
      <h1>Aluminijumska stolarija</h1>
      <p>
        Koristimo visokokvalitetni aluminijumski profil firme Feal. 'Hladni' ili
        profil sa termičkim prekidom nam omogućava da na efikasan i pristupačan
        način zatvorimo prostorije različitih namjena. Profili su dostupni u
        velikom broju boja kao i sa plastificiranim ili eloksiranim zaštitnim
        slojem. Stakla koja ugradjujemo su termopan dvostruka ili trostruka sa
        low-e slojem i punjena Argon gasom po zahtijevu kupca.
      </p>
    </div>
    <SRLWrapper>
      <div className='row'>
        <div className='column'>
          <img
            src='/static/images/aluminijum/aluminijum4.jpg'
            alt='aluminijum gallery'
          />
          <img
            src='/static/images/aluminijum/aluminijum2.jpg'
            alt='aluminijum gallery'
          />
        </div>
        <div className='column'>
          <img
            src='/static/images/aluminijum/aluminijum3.jpg'
            alt='aluminijum gallery'
          />
          <img
            src='/static/images/aluminijum/aluminijum1.jpg'
            alt='aluminijum gallery'
          />
        </div>
        <div className='column'>
          <img
            src='/static/images/aluminijum/aluminijum8.jpg'
            alt='aluminijum gallery'
          />
          <img
            src='/static/images/aluminijum/aluminijum6.jpg'
            alt='aluminijum gallery'
          />
        </div>
        <div className='column'>
          <img
            src='/static/images/aluminijum/aluminijum7.jpeg'
            alt='aluminijum gallery'
          />
          <img
            src='/static/images/aluminijum/aluminijum5.jpg'
            alt='aluminijum gallery'
          />
        </div>
      </div>
    </SRLWrapper>
  </StyledPage>
)

const StyledPage = styled.div`
  padding: 20px 0;
  .info {
    margin-bottom: 40px;
    h1 {
      font-size: 32px;
      text-align: center;
      padding: 20px 0;
    }
    p {
      padding: 0 10px;
      margin: 10px auto;
      max-width: 800px;
      text-align: justify;
    }
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    max-width: 960px;
    margin: 0 auto;
    .column {
      flex: 24%;
      max-width: 24%;
      img {
        cursor: pointer;
        margin-top: 8px;
        margin-left: 15%;
        vertical-align: middle;
        width: 90%;
      }
    }
    @media screen and (max-width: 800px) {
      .column {
        flex: 45%;
        max-width: 45%;
        img {
          margin-left: 10%;
        }
      }
    }
    @media screen and (max-width: 600px) {
      .column {
        flex: 100%;
        max-width: 100%;
        img {
          margin-left: 5%;
        }
      }
    }
  }
`

export default Aluminijum
