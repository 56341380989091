import styled from 'styled-components'
import { SRLWrapper } from 'simple-react-lightbox'

const PVC = () => (
  <StyledPage>
    <div className='info'>
      <h1>PVC stolarija</h1>
      <p>
        Naša PVC Stolarija je proizvedena prateći najnovije evropske standarde i
        trendove u oblasti obrade i primjene ekoloških materijala. Pored vrata,
        prozora i unutrašnje stolarije nudimo i usluge zamjene stare stolarije
        kao i ustakljivanja bašti i balkona.
      </p>
      <p>
        U ponudi stakala imamo termopan dvostruko ili trostruko staklo, low-e
        sloj kao i Argon gas u vidu dodatne termičke barijere, po zahtijevu
        kupca.
      </p>
    </div>
    <SRLWrapper>
      <div className='row'>
        <div className='column'>
          <img src='/static/images/pvc/pvc4.jpg' alt='pvc gallery' />
          <img src='/static/images/pvc/pvc2.jpg' alt='pvc gallery' />
        </div>
        <div className='column'>
          <img src='/static/images/pvc/pvc3.jpg' alt='pvc gallery' />
          <img src='/static/images/pvc/pvc.jpg' alt='pvc gallery' />
        </div>
        <div className='column'>
          <img src='/static/images/pvc/pvc8.jpg' alt='pvc gallery' />
          <img src='/static/images/pvc/pvc6.jpg' alt='pvc gallery' />
        </div>
        <div className='column'>
          <img src='/static/images/pvc/pvc7.jpg' alt='pvc gallery' />
          <img src='/static/images/pvc/pvc5.jpg' alt='pvc gallery' />
        </div>
      </div>
    </SRLWrapper>
  </StyledPage>
)

const StyledPage = styled.div`
  padding: 20px 0;
  .info {
    margin-bottom: 40px;
    h1 {
      font-size: 32px;
      text-align: center;
      padding: 20px 0;
    }
    p {
      padding: 0 10px;
      margin: 10px auto;
      max-width: 800px;
      text-align: justify;
    }
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    max-width: 960px;
    margin: 0 auto;
    .column {
      flex: 24%;
      max-width: 24%;
      img {
        cursor: pointer;
        margin-top: 8px;
        margin-left: 15%;
        vertical-align: middle;
        width: 90%;
      }
    }
    @media screen and (max-width: 800px) {
      .column {
        flex: 45%;
        max-width: 45%;
        img {
          margin-left: 10%;
        }
      }
    }
    @media screen and (max-width: 600px) {
      .column {
        flex: 100%;
        max-width: 100%;
        img {
          margin-left: 5%;
        }
      }
    }
  }
`

export default PVC
