import styled from 'styled-components'
import Hero from '../components/Home/Hero'
import Cards from '../components/Home/Cards'
import Map from '../components/Home/Map'

const Home = () => (
  <div>
    <Hero />
    <Cards />
    <StyledMap>
      <Map />
    </StyledMap>
  </div>
)

const StyledMap = styled.div`
  max-height: 300px;
  width: 100%;
`

export default Home
