import React from 'react'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'

const Map = ({ options }) => {
  const position = [43.810111, 18.569532]
  if (typeof window !== 'undefined') {
    return (
      <MapContainer
        style={{ minHeight: '300px', minWidth: '300px' }}
        center={position}
        zoom={15}
      >
        <TileLayer url='https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png' />
        <Marker position={position}>
          <Popup>Latex DOO</Popup>
        </Marker>
      </MapContainer>
    )
  }
  return null
}

export default Map
