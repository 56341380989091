import styled from 'styled-components'
import React from 'react'

const Kontakt = () => (
  <>
    <Description>
      <h1>Kontakt</h1>
      <p>Kontaktirajte nas za ponude ili vise informacija o našim uslugama.</p>
    </Description>
    <StyledForm name='contact' method='post' action='/'>
      <input type='hidden' name='form-name' value='contact' />
      <label htmlFor='name'>Ime i prezime:</label>
      <input
        type='text'
        name='ime'
        id='ime'
        placeholder='Unesite vaše ime...'
        required
      />
      <label htmlFor='email'>E-mail adresa:</label>
      <input
        type='email'
        name='email'
        id='email'
        placeholder='Unesite e-mail adresu...'
        required
      />
      <label htmlFor='subject'>Naslov:</label>
      <input
        type='text'
        name='subject'
        id='subject'
        placeholder='Unesite naslov vaše poruke...'
        required
      />
      <label htmlFor='message'>Poruka:</label>
      <textarea
        name='message'
        rows='6'
        placeholder='Unesite poruku...'
        required
      />
      <input className='formButton' type='submit' value='Pošalji poruku' />
    </StyledForm>
  </>
)

const Description = styled.div`
  margin-bottom: 40px;
  h1 {
    margin-top: 40px;
    font-size: 32px;
    text-align: center;
  }
  p {
    margin: 10px auto;
    text-align: center;
  }
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 70px;
  max-width: 650px;
  label {
    margin-bottom: 25px;
  }
  input,
  textarea {
    background-color: #eee;
    border: 1px solid #a1a1a1;
    font-family: inherit;
    margin-bottom: 40px;
    padding: 25px 15px;
  }
  .formButton {
    background-color: #1d1d1d;
    color: #eee;
    cursor: pointer;
    :hover {
      opacity: 0.9;
    }
  }
  @media screen and (max-width: 768px) {
    padding-bottom: 35px;
    width: 90%;
    label,
    .formButton {
      font-size: 16px;
    }
    input,
    textarea {
      font-size: 14px;
    }
  }
`

export default Kontakt
