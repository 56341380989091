import styled from 'styled-components'
import { Link } from '@reach/router'

const Cards = () => (
  <StyledCards>
    <h1>Kategorije:</h1>
    <div className='cards'>
      <div className='card'>
        <img src='/static/images/Aluminijum.jpg' alt='Aluminijum' />
        <div className='text'>
          <h2>Aluminijum</h2>
          <Link to='/aluminijum'>Više informacija ></Link>
        </div>
      </div>
      <div className='card'>
        <img src='/static/images/Inox.jpg' alt='Inox' />
        <div className='text'>
          <h2>Inox</h2>
          <Link to='/inox'>Više informacija ></Link>
        </div>
      </div>
      <div className='card'>
        <img src='/static/images/Pvc.jpg' alt='PVC' />
        <div className='text'>
          <h2>PVC</h2>
          <Link to='/pvc'>Više informacija ></Link>
        </div>
      </div>
    </div>
  </StyledCards>
)

const StyledCards = styled.div`
  padding: 70px 0;
  h1 {
    margin-bottom: 10px;
    text-align: center;
  }
  .cards {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    .card {
      background-color: #fff;
      margin: 20px;
      img {
        width: 100%;
      }
      .text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        flex-wrap: wrap;
        > * {
          padding: 5px;
        }
        a {
          color: #2898a7;
          text-decoration: none;
        }
      }
    }
  }
`

export default Cards
