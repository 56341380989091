import styled from 'styled-components'
import { Link } from '@reach/router'

const Menu = ({ open, setOpen }) => (
  <Nav open={open}>
    <Link to='/' onClick={() => setOpen(!open)}>
      Home
    </Link>
    <Link to='/pvc' onClick={() => setOpen(!open)}>
      PVC
    </Link>
    <Link to='/aluminijum' onClick={() => setOpen(!open)}>
      Aluminijum
    </Link>
    <Link to='/inox' onClick={() => setOpen(!open)}>
      Inox
    </Link>
    <Link to='/kontakt' onClick={() => setOpen(!open)}>
      Kontakt
    </Link>
  </Nav>
)

const Nav = styled.nav`
  display: flex;
  a {
    color: #1d1d1d;
    font-size: 18px;
    text-decoration: none;
    margin: 0 10px;
    :hover {
      opacity: 0.8;
    }
  }

  @media screen and (max-width: 1024px) {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
    height: 100vh;
    padding: 2rem;
    position: absolute;
    top: 0;
    right: 0;
    transition: transform 0.3s ease-in-out;
    z-index: 5;
    a {
      font-size: 1.3rem;
      padding: 3rem;
    }
  }

  @media screen and (max-width: 576px) {
    width: 100%;
  }
`

export default Menu
