import { createGlobalStyle } from 'styled-components'

const Layout = () => <GlobalStyle />

const GlobalStyle = createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
  }

  html,
  body {
  overflow-x: hidden;
  position: relative;
  }

  body {
    background-color: #eee;
    font-family: 'Montserrat', sans-serif;
    ::-webkit-scrollbar {
      height: 3px;
      width: 9px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #DAC26D;
    }
  }
  h1 {
    color: #1d1d1d;
  }
  p {
    color: #434343;
  }
`

export default Layout
