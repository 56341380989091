import styled from 'styled-components'
import { Link } from '@reach/router'

import { ReactComponent as Logo } from '../svg/logo.svg'

const Footer = () => (
  <StyledFooter>
    <div className='column'>
      <Link to='/'>
        <Logo />
      </Link>
      <p>Copyright &copy; {new Date().getFullYear()} Latex D.O.O.</p>
    </div>
    <div className='column'>
      <p>Kontakt:</p>
      <p>+387 65 517-678</p>
    </div>
  </StyledFooter>
)

const StyledFooter = styled.footer`
  background-color: #1d1d1d;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  .column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      color: #eee;
      margin-top: 20px;
    }
    svg {
      max-height: 50px;
      max-width: 150px;
    }
    a {
      color: #eee;
      margin-top: 10px;
      text-decoration: none;
      :hover {
        opacity: 0.8;
      }
    }
  }
  @media screen and (max-width: 500px) {
    justify-content: center;
    flex-wrap: wrap;
    .column {
      margin: 0px 20px;
    }
  }
`

export default Footer
