import { Router } from '@reach/router'

import Header from './components/Header'
import Footer from './components/Footer'
import Home from './pages/Home'
import PVC from './pages/PVC'
import Aluminijum from './pages/Aluminijum'
import Inox from './pages/Inox'
import Kontakt from './pages/Kontakt'

import Layout from './components/Layout'

function App() {
  return (
    <div className='App'>
      <Layout />
      <Header />
      <Router primary={false}>
        <Home path='/' />
        <PVC path='/pvc' />
        <Aluminijum path='/aluminijum' />
        <Inox path='/inox' />
        <Kontakt path='/kontakt' />
      </Router>
      <Footer />
    </div>
  )
}

export default App
