import styled from 'styled-components'
import { SRLWrapper } from 'simple-react-lightbox'

const Inox = () => (
  <StyledPage>
    <div className='info'>
      <h1>Inox ograde</h1>
      <p>
        INOX ograde izradjujemo od najkvalitetnijih elemenata i profila. Sistem
        koji koristimo ne zahtijeva varenje i manuelnu obradu vara i omogucava
        izuzetno preciznu izradu i montažu unutrašnjih i vanjskih stepenišnih
        kao i balkonskih ograda. Po dogovoru ugradjujemo horizontalne ili
        vertikalne prečke 'ispune' kao i staklene panele.
      </p>
      <p>
        Uz Inox ograde nudimo i program Aluminijumskih ograda za unutrašnju i
        spoljnu namjenu u nekoliko boja i kombinacija elemenata.
      </p>
    </div>
    <SRLWrapper>
      <div className='row'>
        <div className='column'>
          <img src='/static/images/inox/inox4.jpg' alt='inox gallery' />
          <img src='/static/images/inox/inox2.jpg' alt='inox gallery' />
        </div>
        <div className='column'>
          <img src='/static/images/inox/inox3.jpg' alt='inox gallery' />
          <img src='/static/images/inox/inox1.jpg' alt='inox gallery' />
        </div>
        <div className='column'>
          <img src='/static/images/inox/inox5.jpg' alt='inox gallery' />
          <img src='/static/images/inox/inox6.jpg' alt='inox gallery' />
        </div>
        <div className='column'>
          <img src='/static/images/inox/inox7.jpg' alt='inox gallery' />
        </div>
      </div>
    </SRLWrapper>
  </StyledPage>
)

const StyledPage = styled.div`
  padding: 20px 0;
  .info {
    margin-bottom: 40px;
    h1 {
      font-size: 32px;
      text-align: center;
      padding: 20px 0;
    }
    p {
      margin: 10px auto;
      max-width: 800px;
      padding: 0 10px;
      text-align: justify;
    }
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    max-width: 960px;
    margin: 0 auto;
    .column {
      flex: 24%;
      max-width: 24%;
      img {
        cursor: pointer;
        margin-top: 8px;
        margin-left: 15%;
        vertical-align: middle;
        width: 90%;
      }
    }
    @media screen and (max-width: 800px) {
      .column {
        flex: 45%;
        max-width: 45%;
        img {
          margin-left: 10%;
        }
      }
    }
    @media screen and (max-width: 600px) {
      .column {
        flex: 100%;
        max-width: 100%;
        img {
          margin-left: 5%;
        }
      }
    }
  }
`

export default Inox
