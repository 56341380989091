import styled from 'styled-components'

const Hero = () => (
  <StyledHero>
    <div className='text'>
      <h1>Latex D.O.O.</h1>
      <p>
        Ukoliko mijenjate dotrajalu stolariju ili zatvarate novi objekat, mi
        proizvodimo stolariju po mjeri i namjenski za vaše otvore na objektu.
        Lično dolazimo na lokaciju i uzimamo detaljne mjere kako bismo vam dali
        ponudu. Cijene stolarije uključuju ugradnju, ustakljenje a po dogovoru
        vadjenje stare stolarije kao i dodatnu termiku stakla.
      </p>
    </div>
    <img src='/static/images/Hero.png' alt='Stolarija Latex' />
  </StyledHero>
)

const StyledHero = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
  .text {
    padding: 0 10px;
    h1 {
      margin-bottom: 20px;
    }
    p {
      max-width: 650px;
      text-align: justify;
    }
  }
  img {
    max-width: 450px;
    width: 100%;
  }
`

export default Hero
